import { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import Bubble, { colors, sizes } from "./bubble";

const Livestream = ({ data, closeLivestream, withSound = false }) => {
  let player = null;
  const ref = (p) => {
    player = p;
  };

  const bumperRef = useRef();

  const [isMuted, setIsMuted] = useState(!withSound);

  const liveStreamInfo = data.livestreamData.find(
    (livestream) => livestream.id === data.livestreamSelection
  );

  const handleError = (error) => {
    console.log("failed to autoplay", error);

    setIsMuted(false);
  };

  return (
    <div className="livestream-wrapper">
      <Bubble
        size={sizes.SMALL}
        color={colors.GREEN}
        className="livestream-title"
      >
        <h1>{liveStreamInfo.title ?? "Placeholder title"} </h1>
      </Bubble>
      <div className="inner-wrapper">
        <button className="close-button" onClick={closeLivestream}>
          Close
        </button>
        <div className={`player-wrapper bumper`} ref={ref}>
          {/* {showPlayButton && <PlayButton onClick={handlePlay} />} */}
          <ReactPlayer
            className={`react-player bumper`}
            responsive
            url={liveStreamInfo.link}
            muted={isMuted}
            playsinline
            playing
            width="1920px"
            height="1080px"
            controls={true}
            ref={bumperRef}
            onBuffer={() => {
              console.log("buffering");
            }}
            onBufferEnd={() => {
              console.log("buffering ended");
            }}
            onError={handleError}
          />
        </div>
      </div>
    </div>
  );
};

const PlayButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="play-button-overlay">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1706.667"
        height="1706.667"
        viewBox="0 0 1280 1280"
      >
        <path d="M461 640.1v288.1l4.3-2.5c19.5-11.4 414.4-239.2 449.4-259.3 33.9-19.4 44.8-26.2 44-27-.7-.7-74.3-43.4-163.7-94.9-89.4-51.6-200.1-115.5-246-142-45.9-26.5-84.5-48.7-85.7-49.4l-2.3-1.2v288.2z" />
      </svg>
    </button>
  );
};

export default Livestream;
