const Bubble = (props) => {

	const {size, color, children, className, alignment, id, withHover } = props;

	return (
		<div className={`bubble ${size} bg-${color} ${className} align-${alignment} ${withHover ? 'with-hover' : ''}`} id={id}>
			{children}
		</div>
	)

	
}

export const sizes = {
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large'
}

export const colors = {
	GREY: 'grey',
	ORANGE: 'orange',
	GREEN: 'green',
	PINK: 'pink'
}

export default Bubble;
