import Bubble, { sizes, colors } from "./bubble";

const InfoSection = ({ content }) => {
  return (
    <>
      {content.content.map((contentBlock, i) => (
        <Bubble
          className={i === 0 ? "mt-14" : "mt-24"}
          size={sizes.MEDIUM}
          color={i % 2 === 0 ? colors.GREEN : colors.GREY}
          alignment={contentBlock.alignment}
          key={i}
        >
          <div dangerouslySetInnerHTML={{ __html: contentBlock.text }} />
        </Bubble>
      ))}
    </>
  );
};

export default InfoSection;
