import { useEffect, useRef, useState } from "react";
import Head from "next/head";
import Layout from "../components/layout";
import { getPage, getPrivacyPage, getProgramme } from "../lib/api";
import { CMS_NAME } from "../lib/constants";
import Speaker from "../components/speaker";
import Programme from "../components/programme";
import useSWR, { SWRConfig } from "swr";
import CharacterContainer from "../components/characterContainer";
import Bubble, { sizes, colors } from "../components/bubble";
import IntroSection from "../components/introSection";
import InfoSection from "../components/infoSection";
import Menu from "../components/menu";
import { Waypoint } from "react-waypoint";
import Livestream from "../components/livestream";

export default function Index({ homePage, preview, fallback }) {
	const [animationTrigger, setAnimationTrigger] = useState(0);

	const [isLivestreamOpen, setIsLivestreamOpen] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const [withSound, setWithSound] = useState(false);
	const hasLivestream = homePage.liveStream.livestreamSelection !== 'none';

	useEffect(() => {
		if (hasLivestream) {

			if(window.innerWidth < 768){
				setIsReady(true);
				return;
			}
			console.log('open livestream')

			setIsLivestreamOpen(true);
			document.body.classList.add('noscroll');
		}
		setIsReady(true);
	}, [])


	const handleCloseLivestream = () => {
		setIsLivestreamOpen(false);
		document.body.classList.remove('noscroll');
	}

	const handleOpenLivestream = () => {
		setWithSound(true);
		setIsLivestreamOpen(true);
		document.body.classList.add('noscroll');
		
	}

	const sections = {
		info: {
			slug: "info",
			ref: useRef(),
		},
		speakers: {
			slug: "speakers",
			ref: useRef(),
		},
		timetable: {
			slug: "timetable",
			ref: useRef(),
		},
	};

	if (!homePage?.homePage) {
		return null;
	}

	const [activeSection, setActiveSection] = useState("index");
	const handleWaypointEnter = (section) => {
		setActiveSection(section);
	};

	const handleMenuClick = (slug) => {
		console.log(slug);
		// setIsAnimating(prev => !prev)
		setAnimationTrigger((prev) => prev + 1);
	};

	const {
		ticketLinks,
		speakers,
		timetable,
		title,
		description,
		keywords,
		tags,
		infoSection,
		introSection,
	} = homePage.homePage;

	const [headerBg, setHeaderBg] = useState(false);
	const handleHeaderBg = (value) => {
		setHeaderBg(value);
	};
	return (
		<Layout preview={preview}>
			<Head>
				<title>{title}</title>
				<meta name="keywords" content={keywords ?? ""} />
				<meta name="description" content={description ?? ""} />
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description ?? ""} />
				<meta property="og:site_name" content={title} />
			</Head>

			{isLivestreamOpen && 
				<Livestream
					closeLivestream={handleCloseLivestream}
					data={homePage.liveStream}
					withSound={withSound}
				/>
			}
			<div className="main-wrapper">
				<Menu
					hasBg={headerBg}
					title={title}
					onClickItem={handleMenuClick}
					activeItem={activeSection}
					ticketLinks={ticketLinks}
					hasLivestream={hasLivestream}
					openLivestream={handleOpenLivestream}
				/>
				<div className="identity">
					{isReady && <CharacterContainer
						setHeaderBg={handleHeaderBg}
						animationTrigger={animationTrigger}
						pauseLoading={isLivestreamOpen}
					/>}
				</div>
				<div className="content">
					<Bubble size={sizes.MEDIUM} color={colors.GREY}>
						<Waypoint onEnter={() => handleWaypointEnter("index")} />
						<IntroSection content={introSection} />
					</Bubble>

					<div id="info" className="info-section">
						<Waypoint
							onEnter={() => handleWaypointEnter(sections.info.slug)}
							bottomOffset="70%"
							ref={sections.info.ref}
						/>
						<Bubble size={sizes.SMALL} color={colors.GREEN}>
							<h2 className="inline-block">{infoSection.title}</h2>
						</Bubble>

						<InfoSection content={infoSection} />
					</div>

					<SWRConfig value={{ fallback }}>
						<Waypoint
							onEnter={() => handleWaypointEnter(sections.timetable.slug)}
							bottomOffset="30%"
							ref={sections.timetable.ref}
						/>
						<Programme title={timetable.title} />
					</SWRConfig>

					{speakers.length ? (
						<>
							<Bubble
								size={sizes.SMALL}
								color={colors.ORANGE}
								id={sections.speakers.slug}
							>
								<Waypoint
									onEnter={() => handleWaypointEnter(sections.speakers.slug)}
									bottomOffset="40%"
									ref={sections.speakers.ref}
								/>
								<h2 className="inline-block">Speakers</h2>
							</Bubble>
							<Bubble
								size={sizes.LARGE}
								color={colors.ORANGE}
								className="speakers-bubble"
							>
								<div className="speakers-list">
									{speakers.map((speaker, i) => (
										<Speaker key={`speaker-${i}`} speaker={speaker} />
									))}
								</div>
							</Bubble>
						</>
					) : null}
				</div>
			</div>
		</Layout>
	);
}

export const getStaticProps = async ({ preview = false }) => {
	console.log("get static props");
	const homePage = await getPage("home", preview);

	const programme = await getProgramme();

	return {
		props: {
			homePage: homePage ?? {},
			preview,
			fallback: {
				"/api/programme": programme ?? {},
			},
		},
	};
};
