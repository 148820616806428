import * as NextImage from "next/image";
import { useRef, useEffect, useState } from "react";

const NR_IMAGES = 250;

const pad = (nr) => {
  var s = "000" + nr;
  return s.substring(s.length - 4);
};

const hasWebpSupport = () =>
  new Promise((resolve) => {
    const image = new Image();
    image.addEventListener("error", () => resolve(false));
    image.addEventListener("load", () => resolve(image.width === 1));
    image.src =
      "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=";
  }).catch(() => false);

const CharacterContainer = ({ animationTrigger, setHeaderBg, pauseLoading }) => {
  const [reduceMotion, setReduceMotion] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

    setReduceMotion(!mediaQuery || mediaQuery.matches);
  }, []);

  return (
    <div className="character-container">
      <ImageCanvas
        setHeaderBg={setHeaderBg}
        width={1600}
        height={1100}
        numFrames={NR_IMAGES}
        reduceMotion={reduceMotion || pauseLoading}
      />
    </div>
  );
};

export default CharacterContainer;

const ImageCanvas = ({
  scrollHeight,
  numFrames,
  reduceMotion,
  setHeaderBg,
}) => {
  const canvasRef = useRef(null);
  const [images, setImages] = useState({});
  const [frameIndex, setFrameIndex] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const preloadImage = (index, webP) =>
    new Promise((resolve, reject) => {
      const fileFormat = webP ? "webp" : "png";
      const basePath = `/sequence/${fileFormat}/`;
      const fileName = pad(index);

      const imgSrc = `${basePath}${fileName}.${fileFormat}`;

      const img = new Image();

      img.onload = () => {
        setImages((prevImages) => ({
          ...prevImages,
          [index - 1]: img,
        }));

        resolve();
      };

      img.onerror = function (e) {
        console.error(`failed to load: ${imgSrc}`, e);
        reject();
      };

      img.src = imgSrc;
    });

  const preloadImages = async () => {
    const promises = [];

    const enableWebP = await hasWebpSupport();

    const start = window.performance.now();

    for (let i = 1; i <= numFrames; i++) {
      promises.push(preloadImage(i, enableWebP));
    }

    await Promise.all(promises);
    setIsLoading(false);

	const end = window.performance.now();
    console.log(`Loading images took: ${end - start}ms`);
  };

  const handleScroll = () => {
    const scrollFraction =
      window.scrollY /
      (document.documentElement.scrollHeight - window.innerHeight);
    const index = Math.min(
      numFrames - 1,
      Math.ceil(scrollFraction * numFrames)
    );

    if (scrollFraction > 0.07) {
      setHeaderBg(true);
    } else {
      setHeaderBg(false);
    }

    if (index <= 0 || index > numFrames) {
      return;
    }

    setFrameIndex(index);
  };

  const renderCanvas = (windowHeight, windowWidth) => {
    const ratio = 1.3333;
    const reverseRatio = 0.75;
    const context = canvasRef.current.getContext("2d");

    let canvasHeight = windowHeight;
    let canvasWidth = windowHeight * ratio;

    console.log(windowHeight * ratio);
    console.log(windowWidth);

    if (windowHeight * ratio < windowWidth) {
      canvasHeight = windowWidth * reverseRatio;
      canvasWidth = windowWidth;
    }
    context.canvas.width = canvasWidth * 2;
    context.canvas.height = canvasHeight * 2;
  };

  useEffect(() => {
    // If user perfers reduced motion, we don't initialise the animated character
    if (reduceMotion) {
      return;
    }
    preloadImages();
    renderCanvas(window.innerHeight, window.innerWidth);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [reduceMotion]);

  useEffect(() => {
    if (!canvasRef.current || images.length < 1 || isLoading) {
      return;
    }

    const context = canvasRef.current.getContext("2d");
    let requestId;
    let prevFrame;

    const render = () => {
      // Only re-render if the frame index has changed
      if (prevFrame === frameIndex) {
        return;
      }

      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context.drawImage(
        images[frameIndex],
        0,
        0,
        context.canvas.width,
        context.canvas.height
      );
      prevFrame = frameIndex;
      requestId = requestAnimationFrame(render);
    };

    render();

    return () => cancelAnimationFrame(requestId);
  }, [frameIndex, images, isLoading]);

  const altText =
    "Theatrical pose of a digital figure with a picture frame composing its body opens its chest up like a door, revealing virtual doorways.";

  return (
    <div style={{ height: scrollHeight }} className="character-wrapper">
      <NextImage
        src="/sequence/png/0001.png"
        alt={altText}
        width={24}
        height={7}
        quality={1}
        style={{ opacity: !isLoading ? 0 : 1 }}
        priority
        aria-hidden={isLoading ? "false" : "true"}
      />
      <canvas
        role="img"
        aria-label={altText}
        style={{ opacity: isLoading ? 0 : 1 }}
        ref={canvasRef}
      />
    </div>
  );
};
