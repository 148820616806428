import { useState } from "react";

const Speaker = ({ speaker, isSecond }) => {
  const [isBioVisible, setIsBioVisible] = useState(false);

  const id = Math.random();

  return (
    <div className={isSecond ? "second-speaker" : "speaker"}>
      {isSecond && <span className="duo-sign">+</span>}
      <h3 className="speaker-name">{speaker.speakerName}</h3>

      {speaker?.speakerBio?.length ? (
        <div className="bio">
          <button
            onClick={() => setIsBioVisible((prev) => !prev)}
            className={`bio-tag ${isBioVisible ? "hide" : ""}`}
            aria-label={`Open bio for ${speaker.speakerName}`}
            aria-controls={`expand_${id}`}
            aria-expanded={isBioVisible}
            aria-hidden={isBioVisible ? "true" : "false"}
          >
            Bio
          </button>
          <span
            id={id}
            tabIndex="-1"
            aria-hidden={isBioVisible ? "false" : "true"}
            className={`speaker-bio ${isBioVisible ? "visible" : ""}`}
          >
            {speaker.speakerBio}
          </span>
        </div>
      ) : null}

      {speaker.speakerDuo && (
        <Speaker
          isSecond
          speaker={{
            speakerName: speaker.secondSpeakerFullName,
            speakerBio: speaker.secondSpeakerBio,
          }}
        />
      )}
    </div>
  );
};

const secondSpeaker = (name, bio) => {};

export default Speaker;
