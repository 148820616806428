import { useRouter } from "next/router";

const IntroSection = ({ content }) => {
  const { title, date, location, links, institutions } = content;

  return (
    <div className="intro-section">
      <h2>{title}</h2>
      <h2>{date}</h2>
      <h2>{location}</h2>

      <div className="links">
        {links.map((link, i) => {
          return <Link linkObject={link} key={i} />;
        })}
      </div>

      {institutions?.length ? (
        <h3>
          {institutions.map(({ institutionName, link }) => (
            <a
              className="institution"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              key={link}
            >
              {institutionName}
            </a>
          ))}
        </h3>
      ) : null}
    </div>
  );
};

const Link = ({ linkObject }) => {
  const router = useRouter();

  const { text, link } = linkObject;

  const openEmail = (e, encodedEmail) => {
    e.preventDefault();

    const decoded = Buffer.from(encodedEmail, "base64").toString("binary");

    router.push(decoded);
  };

  if (link.includes("@")) {
    const encodedLink = Buffer.from(link, "binary").toString("base64");

    return (
      <a
        href={`mailto:${encodedLink}`}
        onClick={(e) => openEmail(e, encodedLink)}
      >
        {text}
      </a>
    );
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      {text}
    </a>
  );
};

export default IntroSection;
