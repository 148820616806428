import Link from "next/link";

export default function Footer() {
  return (
    <footer>
      <Link className="privacy-policy-button" href="/privacy-policy">
        Privacy & Disclaimer
      </Link>
    </footer>
  );
}
