const API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;
import { homePage, programmeItem, archiveEntry } from "./fragments";

async function fetchAPI(query = "", props) {
  const headers = { "Content-Type": "application/json" };

  // console.log('fetch api', API_URL)

  // Later perhaps, to set up Draft previews
  // if (process.env.WORDPRESS_AUTH_REFRESH_TOKEN) {
  // 	headers[
  // 		'Authorization'
  // 	] = `Bearer ${process.env.WORDPRESS_AUTH_REFRESH_TOKEN}`
  // }

  let res;
  try {
    // WPGraphQL Plugin must be enabled
    res = await fetch(API_URL, {
      headers,
      method: "POST",
      body: JSON.stringify({
        query,
        variables: props?.variables ?? "",
      }),
    });
  } catch (e) {
    console.error(e);
  }

  const json = await res.json();
  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }
  return json.data;
}

export async function getPage(id, preview) {
  const data = await fetchAPI(
    `
		query PageBySlug($id: String!) {
		  pageBy(uri: $id) {
			  ${homePage}
        content
		  }
		}`,
    {
      variables: {
        onlyEnabled: !preview,
        preview,
        id,
      },
    }
  );

  return data?.pageBy;
}

export async function getPrivacyPage() {
  const data = await fetchAPI(
    `
		query privacyPage {
			pageBy(uri: "privacy-policy"){
				title
    		content
			}
		}`
  );

  return data?.pageBy;
}

export async function getArchiveItems() {
  const data = await fetchAPI(`
    query AllArchiveItems {
      archiveEntries(first: 400){
        nodes {
          ${archiveEntry}
        }
      }
    }
    `);

  console.log(data.archiveEntries.nodes);
  if (!data?.archiveEntries?.nodes?.length) {
    return null;
  }

  return data.archiveEntries.nodes;
}

export async function getProgramme() {
  const data = await fetchAPI(
    `
		query AllProgrammeItems {
			programmeItems(first: 100) {
				nodes {
					date
					title
					${programmeItem}
				}
			}
		}`
  );

  if (!data?.programmeItems?.nodes?.length) {
    return null;
  }

  const DAYS = [
    {
      title: "Friday",
      date: "10 March",
      items: [],
    },
    {
      title: "Saturday",
      date: "11 March",
      items: [],
    },
    {
      title: "Sunday",
      date: "12 March",
      items: [],
    },
  ];

  const items = data.programmeItems.nodes;

  if (!items.length) {
    return null;
  }

  items
    .sort((a, b) =>
      a.programmeItem.startTime.localeCompare(b.programmeItem.startTime)
    )
    .forEach((item) => {
      if (item.programmeItem.day === "one") {
        DAYS[0].items.push(item);
      }

      if (item.programmeItem.day === "two") {
        DAYS[1].items.push(item);
      }

      if (item.programmeItem.day === "three") {
        DAYS[2].items.push(item);
      }
    });

  return DAYS;
}

export async function getPreviewPost(id, idType = "DATABASE_ID") {
  console.log(idType);

  await getPage();
}

export async function getAllPostsWithSlug() {
  const data = await fetchAPI(`
    {
      posts(first: 10000) {
        edges {
          node {
            slug
          }
        }
      }
    }
  `);
  return data?.posts;
}

export async function getAllPostsForHome(preview) {
  const data = await fetchAPI(
    `
    query AllPosts {
      posts(first: 20, where: { orderby: { field: DATE, order: DESC } }) {
        edges {
          node {
            title
            excerpt
            slug
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
            author {
              node {
                name
                firstName
                lastName
                avatar {
                  url
                }
              }
            }
          }
        }
      }
    }
  `,
    {
      variables: {
        onlyEnabled: !preview,
        preview,
      },
    }
  );

  return data?.posts;
}
