const acf_home_institutions = `
	institutions {
		fieldGroupName
		institutionLink
		institutionName
	}
`;

const acf_home_speakers = `
	speakers {
		speakerName
		speakerBio
		speakerDuo
		secondSpeakerFullName
		secondSpeakerBio
	}
`;

const acf_home_timetable_section = `
	timetable {
		title
	}
`;

const acf_home_intro_section = `
	introSection {
		title
		location
		fieldGroupName
		date
		links {
			link
			text
		}
		institutions {
			institutionName
			link
		}
	}
`;

const acf_home_info_section = `
	infoSection {
		title
		content {
			text
			alignment
		}
	}
`;

const acf_home_tickets_section = `
	ticketLinks {
		label
		link
	}
`;

const acf_livestream_section = `
	liveStream {
		livestreamData {
			title
			link
			id
		}
		livestreamSelection
	}
`;

const acf_homePage = `
	homePage {
		title
		description
		keywords
		${acf_home_tickets_section}
		${acf_home_intro_section}
		${acf_home_info_section}
		${acf_home_speakers}
		${acf_home_timetable_section}
	}
	${acf_livestream_section}
`;

const homePage = `
	title
	databaseId
	status
	slug
	${acf_homePage}
`;

const programmeItem = `
	programmeItem {
		title
		fieldGroupName
		format
		startTime
		endTime
		day
	}
`;

const videosFragment = `
	videos {
		title
		link
	}
`;
const documentsFragment = `
	documents {
		label
		document {
			sourceUrl
			mediaItemUrl
		}
	}
`;

const photosFragment = `
	photos {
		sourceThumb: sourceUrl(size: MEDIUM_LARGE)
		sourceHighRes: sourceUrl(size: _1536X1536)
		mediaItemUrl
		caption
		altText
		mediaDetails {
			width
			height
		}
	}
`;

const archiveEntryDetails = `
	archiveEntry {
		type
		${videosFragment}
		${documentsFragment}
		${photosFragment}
	}
`;

const archiveEntry = `
	title
	content
	${archiveEntryDetails}
`;

export { homePage, programmeItem, archiveEntry };
