import Head from "next/head";
import Script from "next/script";

export default function Meta() {
  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#000000"
        />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta
          name="msapplication-config"
          content="/favicon/browserconfig.xml"
        />
        <meta name="theme-color" content="#000" />

        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* OG */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ageofmetaverse.art" />
        <meta
          property="og:image"
          content="https://cms.ageofmetaverse.art/wp-content/uploads/2023/01/OG-Image.jpg"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Other */}
        <meta name="apple-mobile-web-app-capable" content="yes"></meta>
      </Head>
      <Script
        id="coockie-consent"
        src="https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js"
        onLoad={() => {
          window.cookieconsent.run({
            notice_banner_type: "simple",
            consent_type: "express",
            palette: "light",
            language: "en",
            page_load_consent_levels: [
              "strictly-necessary",
              "functionality",
              "tracking",
            ],
            notice_banner_reject_button_hide: false,
            preferences_center_close_button_hide: false,
            page_refresh_confirmation_buttons: false,
            website_name: "Art in the Age of Metaverse",
            website_privacy_policy_url:
              "https://ageofmetaverse.art/privacy-policy",
          });
        }}
      />

      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-BPTBX5MEYJ"
      />
      <Script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-BPTBX5MEYJ');
        `}
      </Script>
    </>
  );
}
