import * as NextLink from "next/link";
import { useEffect, useState } from "react";
import { Link, animateScroll } from "react-scroll";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";

const Menu = ({
  title,
  activeItem,
  hasBg,
  ticketLinks,
  hasLivestream,
  openLivestream,
}) => {
  const items = [
    {
      slug: "archive",
      title: "Archive",
      type: "page",
    },
    {
      slug: "info",
      title: "Info",
    },
    {
      slug: "timetable",
      title: "Timetable",
    },
    {
      slug: "speakers",
      title: "Speakers",
    },
  ];

  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(
    ticketLinks.length
  );

  const handleScroll = (e) => {
    e.preventDefault();
    animateScroll.scrollToTop();
  };

  const onClickItem = () => {
    setIsOpen(false);
  };

  const [offset, setOffset] = useState(-20);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setOffset(-110);
    }
  }, []);

  return (
    <div className={`header ${hasBg ? "has-bg" : ""}`}>
      <a onClick={handleScroll} href="#home" className="title">
        <h1>{title}</h1>
      </a>
      <div className="navigation">
        <ul aria-orientation="horizontal" role="menubar">
          {hasLivestream && (
            <li data-menubar-listitem role="button">
              <button className="livestream-button" onClick={openLivestream}>
                <span>●</span> Livestream
              </button>
            </li>
          )}
          {items.map(({ slug, title, children, type }) => {
            let className = `button-${slug}`;

            if (slug === activeItem) {
              className = `${className} active`;
            }

            if (children?.length) {
              className = `${className} bg-orange`;
            }

            return (
              <li data-menubar-listitem role="none" key={slug}>
                {type === "page" ? (
                  <NextLink href={slug} className={className}>
                    {title}
                  </NextLink>
                ) : (
                  <Link
                    // href={`#${slug}`}
                    to={slug}
                    className={className}
                    onClick={onClickItem}
                    smooth={false}
                    duration={1000000}
                    delay={0}
                    offset={offset}
                    activeClass="test"
                    spy={true}
                    tabIndex="0"
                    href={`#${slug}`}
                    {...(children?.length ? buttonProps : {})}
                  >
                    {title}
                  </Link>
                )}
                {/* {children?.length ? (
                  <ul
                    className={`submenu ${isOpen ? "open" : "closed"}`}
                    role="menu"
                  >
                    {children.map(({ label, link }, index) => (
                      <li
                        {...itemProps[index]}
                        aria-disabled={link?.length ? "false" : "true"}
                        aria-label={
                          link?.length
                            ? `Link to ${label}`
                            : `${label} tickets coming soon.`
                        }
                        key={index}
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={link}
                          disabled={!link?.length}
                          className={link?.length ? "" : "disabled"}
                          title={link?.length ? "" : "Coming soon"}
                        >
                          {label}
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : null} */}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
