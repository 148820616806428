import useSWR from "swr";
import { getProgramme } from "../lib/api";
import Bubble, { colors, sizes } from "./bubble";

const fetcher = (...args) => getProgramme(...args).then((res) => res);

const Programme = ({ title }) => {
  const { data } = useSWR("/api/programme", fetcher);

  return (
    <>
      <Bubble color={colors.GREEN} size={sizes.SMALL}>
        <h2 id="timetable">{title}</h2>
      </Bubble>
      <div className="programme-wrapper">
        {data?.length &&
          data.map((day) => <DayWrapper day={day} key={day.title} />)}
      </div>
    </>
  );
};

const DayWrapper = ({ day }) => {
  return (
    <Bubble size={sizes.MEDIUM} color={colors.GREEN} className="day-wrapper">
      <div className="title-wrapper"><h3>{day.title}</h3>
      <h4>{day.date} </h4></div>
      {day.items.map((item, i) => (
        <ProgrammeItem item={item.programmeItem} key={i} />
      ))}
    </Bubble>
  );
};

const ProgrammeItem = ({ item }) => {
  const { title, startTime, endTime, format } = item;
  return (
    <div className="programme-item">
      <h5>{title}</h5>
      <span
        className="sans-serif time"
      >
        {startTime} - {endTime}
      </span>
      <span>{format ?? ""}</span>
    </div>
  );
};

export default Programme;
